import React from 'react';
import { Menu, MenuItem, useMediaQuery, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import { useAuthentication } from '../../../context/authentication';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthentication } from '../../../contexts/authentication';

interface HeaderMenu {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

const HeaderMenu = ({ anchorEl, handleClose }: HeaderMenu) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const { logout } = useAuthentication();
  const history = useHistory();
  const location = useLocation();
  const shouldShowProfile = !(location.pathname === '/profile');
  const shouldShowHelp = !['/profile', '/help'].find(path => location.pathname.includes(path));

  const handleHelpItemSelection = () => {
    history.push(`/help${location.pathname}`);
    handleClose();
  };

  const handleProfileItemSelection = () => {
    history.push('/profile');
    handleClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      id="header-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {shouldShowProfile && (
        <MenuItem onClick={handleProfileItemSelection}>{t('layout.header.menu.settings')}</MenuItem>
      )}
      {isMobile && (
        <div>
          <MenuItem
            onClick={() =>
              (window.location.href =
                (process.env.REACT_APP_LAUNCHER_URL && process.env.REACT_APP_LAUNCHER_URL) || '')
            }
          >
            {'Flex Tiles'}
          </MenuItem>
          {shouldShowHelp && (
            <MenuItem onClick={handleHelpItemSelection}>{t('layout.header.menu.help')}</MenuItem>
          )}
        </div>
      )}
      <MenuItem onClick={() => logout()}>{t('layout.header.menu.logout')}</MenuItem>
    </Menu>
  );
};

export default HeaderMenu;
