import { Store } from '../types';

export const compareStores = (a: Store, b: Store): number => {
  if (a.storeAcronym < b.storeAcronym) return -1;
  if (a.storeAcronym > b.storeAcronym) return 1;
  return 0;
};

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
