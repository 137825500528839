/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelLanguage, WayStatus } from '../types';
import { useAlert } from './alert';
import { useApi } from './api';
import { useFilters } from './filters';

import en from '../static/locales/en.json';
import es from '../static/locales/es.json';
import fr from '../static/locales/fr.json';
import pt from '../static/locales/pt.json';

interface WayStatusContext {
  status: WayStatus[];
  loading: boolean;
  requestExport: () => Promise<void>;
  getReport: () => Promise<void>;
}

const initialState: WayStatusContext = {
  status: [],
  loading: false,
  requestExport: () => new Promise(() => {}),
  getReport: () => new Promise(() => {}),
};

const WayStatusContext = createContext(initialState);

export const WayStatusProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [status, setStatus] = useState<WayStatus[]>([]);
  const [loading, setLoading] = useState(false);
  const { getTransformedFilters, getFilterLabels } = useFilters();
  const { fetchWayStatus } = useApi();
  const { filters } = useFilters();
  const { showError, showAlert } = useAlert();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (status.length > 0) setStatus([]);
    // eslint-disable-next-line
  }, [filters]);

  const getReport = async () => {
    try {
      setLoading(true);
      setStatus((await fetchWayStatus(getTransformedFilters())).data);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const requestExport = async () => {
    try {
      setLoading(true);
      let labels: LabelLanguage = {};
      switch (i18n.language) {
        case 'en':
          labels = { ...en.table.columns };
          break;
        case 'es':
          labels = { ...es.table.columns };
          break;
        case 'fr':
          labels = { ...fr.table.columns };
          break;
        case 'pt':
          labels = { ...pt.table.columns };
          break;
        default:
          break;
      }
      showAlert(t('dialogs.exportRequested.title'), t('dialogs.exportRequested.body'));
      await fetchWayStatus(getTransformedFilters(), true, labels, getFilterLabels());
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <WayStatusContext.Provider value={{ status, loading, requestExport, getReport }}>
      {children}
    </WayStatusContext.Provider>
  );
};

export const useWayStatus = (): WayStatusContext => useContext(WayStatusContext);
