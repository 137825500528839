/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { format } from 'date-fns';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterLabels,
  Management,
  PosState,
  Region,
  RegionalManagement,
  Store,
  Supervision,
  TransformedFilters,
  WayFilters,
  WayState,
} from '../types';

interface FiltersContext {
  filters: WayFilters;
  setDivision: (newDivision: string) => void;
  setCountries: (newCountries: string[]) => void;
  setRegionalManagements: (newRegionalManagements: RegionalManagement[]) => void;
  setRegions: (newRegions: Region[]) => void;
  setManagements: (newManagements: Management[]) => void;
  setSupervisions: (newSupervisions: Supervision[]) => void;
  setStores: (newStores: Store[]) => void;
  setDateRange: (range: { fromDate: Date; toDate: Date }) => void;
  setWayState: (newValue: WayState) => void;
  setPosState: (newValue: PosState[]) => void;
  setViewMode: (_newValue: 'last-snapshot' | 'date-range') => void;
  updateFilters: (newFilters: Partial<WayFilters>) => void;
  getTransformedFilters: () => TransformedFilters;
  getFilterLabels: () => FilterLabels;
  resetFilters: () => void;
}

const initialState: FiltersContext = {
  filters: {
    fromDate: new Date(),
    toDate: new Date(),
    division: '',
    countries: [],
    regionalManagements: [],
    regions: [],
    managements: [],
    supervisions: [],
    stores: [],
    online: '',
    wayState: '',
    posState: [],
    viewMode: 'last-snapshot',
    diffVersion: false,
    diffPackage: false,
    diffSystemDate: false,
  },
  setDivision: (_newDivision: string) => {},
  setCountries: (_newCountries: string[]) => {},
  setRegionalManagements: (_newRegionalManagements: RegionalManagement[]) => {},
  setRegions: (_newRegions: Region[]) => {},
  setManagements: (_newManagements: Management[]) => {},
  setSupervisions: (_newSupervisions: Supervision[]) => {},
  setStores: (_newStores: Store[]) => {},
  setDateRange: (_range: { fromDate: Date; toDate: Date }) => {},
  setWayState: (_newValue: WayState) => {},
  setPosState: (_newValue: PosState[]) => {},
  setViewMode: (_newValue: 'last-snapshot' | 'date-range') => {},
  updateFilters: (_newFilters: Partial<WayFilters>) => {},
  getTransformedFilters: () => ({
    fromDate: '',
    toDate: '',
    division: '',
  }),
  getFilterLabels: () => ({}),
  resetFilters: () => {},
};

const FiltersContext = createContext(initialState);

export const FiltersProvider = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();
  const [filters, setFilters] = useState<WayFilters>(initialState.filters);

  const setDivision = (newDivision: string) =>
    setFilters(f => ({
      ...f,
      division: newDivision,
      countries: [],
      regionalManagements: [],
      regions: [],
      managements: [],
      supervisions: [],
      stores: [],
    }));

  const setCountries = (newCountries: string[]) =>
    setFilters(f => ({
      ...f,
      countries: newCountries,
      regionalManagements: [],
      regions: [],
      managements: [],
      supervisions: [],
      stores: [],
    }));

  const setRegionalManagements = (newRM: RegionalManagement[]) =>
    setFilters(f => ({
      ...f,
      regionalManagements: newRM,
      regions: [],
      managements: [],
      supervisions: [],
      stores: [],
    }));

  const setRegions = (newRegions: Region[]) =>
    setFilters(f => ({
      ...f,
      regions: newRegions,
      managements: [],
      supervisions: [],
      stores: [],
    }));

  const setManagements = (newM: Management[]) =>
    setFilters(f => ({
      ...f,
      managements: newM,
      supervisions: [],
      stores: [],
    }));

  const setSupervisions = (newS: Supervision[]) =>
    setFilters(f => ({
      ...f,
      supervisions: newS,
      stores: [],
    }));

  const setStores = (newS: Store[]) =>
    setFilters(f => ({
      ...f,
      stores: newS,
    }));

  const setDateRange = (newRange: { fromDate: Date; toDate: Date }) =>
    setFilters(f => ({ ...f, fromDate: newRange.fromDate, toDate: newRange.toDate }));

  const setWayState = (newValue: WayState) => {
    setFilters(f => ({ ...f, wayState: newValue }));
  };

  const setPosState = (newValue: PosState[]) => {
    setFilters(f => ({ ...f, posState: newValue }));
  };

  const setViewMode = (newValue: 'last-snapshot' | 'date-range') => {
    setFilters(f => ({ ...f, viewMode: newValue }));
  };

  const updateFilters = (newFilters: Partial<WayFilters>) => {
    setFilters(f => ({ ...f, ...newFilters }));
  };

  const { t } = useTranslation();

  const getTransformedFilters = (): TransformedFilters => {
    const joinedCountries = filters.countries.join();
    const joinedRegionalManagements = filters.regionalManagements.map(rm => rm.id).join();
    const joinedRegions = filters.regions.map(r => r.id).join();
    const joinedManagements = filters.managements.map(m => m.id).join();
    const joinedSupervisions = filters.supervisions.map(s => s.id).join();
    const joinedStores = filters.stores.map(s => s.storeId).join();
    const joinedPosState = filters.posState.join();
    const { fromDate, toDate } = filters;
    return {
      division: filters.division,
      fromDate:
        filters.viewMode === 'date-range' ? format(fromDate, 'yyyy-MM-dd HH:mm:ss') : undefined,
      toDate: filters.viewMode === 'date-range' ? format(toDate, 'yyyy-MM-dd HH:mm:ss') : undefined,
      country: joinedCountries !== '' ? joinedCountries : undefined,
      regional: joinedRegionalManagements !== '' ? joinedRegionalManagements : undefined,
      region: joinedRegions !== '' ? joinedRegions : undefined,
      mgmt: joinedManagements !== '' ? joinedManagements : undefined,
      supervision: joinedSupervisions !== '' ? joinedSupervisions : undefined,
      store: joinedStores !== '' ? joinedStores : undefined,
      wayState: filters.wayState === '' ? undefined : filters.wayState,
      posState: joinedPosState !== '' ? joinedPosState : undefined,
      online: filters.online !== '' ? filters.online : undefined,
      viewMode: t(`toolbar.labels.${filters.viewMode}`),

      diffVersion: filters.diffVersion,
      diffPackage: filters.diffPackage,
      diffSystemDate: filters.diffSystemDate,
    };
  };

  const getFilterLabels = (): FilterLabels => {
    return {
      regional:
        filters.regionalManagements.length > 0
          ? filters.regionalManagements.map(rm => rm.label)
          : undefined,
      region: filters.regions.length > 0 ? filters.regions.map(r => r.label) : undefined,
      mgmt: filters.managements.length > 0 ? filters.managements.map(m => m.label) : undefined,
      supervision:
        filters.supervisions.length > 0 ? filters.supervisions.map(s => s.label) : undefined,
      store: filters.stores.length > 0 ? filters.stores.map(s => s.storeAcronym) : undefined,
      language: i18n.language.substring(0, 2),
    };
  };

  const resetFilters = () => {
    setFilters(initialState.filters);
  };

  return (
    <FiltersContext.Provider
      value={{
        filters,
        setDivision,
        setCountries,
        setRegionalManagements,
        setRegions,
        setManagements,
        setSupervisions,
        setStores,
        setDateRange,
        setWayState,
        setPosState,
        setViewMode,
        getTransformedFilters,
        getFilterLabels,
        updateFilters,
        resetFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = (): FiltersContext => useContext(FiltersContext);
