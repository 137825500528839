/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useState, useContext, useEffect } from 'react';
import {
  Store,
  MasterData,
  RegionalManagement,
  Region,
  Management,
  Supervision,
  Country,
} from '../types';
import { useAlert } from './alert';
import { compareStores } from '../utils/functions';
import { useFilters } from './filters';
import { useAuthentication } from './authentication';
import { useApi } from './api';

interface MasterDataContext {
  companyDivisions: string[];
  companyCountries: string[];
  companyRegionalManagements: RegionalManagement[];
  companyRegions: Region[];
  companyManagements: Management[];
  companySupervisions: Supervision[];
  companyStores: Store[];
  loading: boolean;
}

const initialState: MasterDataContext = {
  companyDivisions: [],
  companyCountries: [],
  companyRegionalManagements: [],
  companyRegions: [],
  companyManagements: [],
  companySupervisions: [],
  companyStores: [],
  loading: false,
};

const MasterDataContext = createContext(initialState);

export const MasterDataProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false);
  const [masterData, setMasterData] = useState<MasterData | undefined>(undefined);
  const [companyDivisions, setCompanyDivisions] = useState<string[]>([]);
  const [companyCountries, setCountries] = useState<string[]>([]);
  const [companyRegionalManagements, setCompanyRegionalManagements] = useState<
    RegionalManagement[]
  >([]);
  const [companyRegions, setCompanyRegions] = useState<Region[]>([]);
  const [companyManagements, setCompanyManagements] = useState<Management[]>([]);
  const [companySupervisions, setCompanySupervisions] = useState<Supervision[]>([]);
  const [companyStores, setStores] = useState<Store[]>([]);
  const { showError } = useAlert();
  const { filters, updateFilters } = useFilters();
  const { division, countries, regionalManagements, regions, managements, supervisions } = filters;
  const { isAuthenticated } = useAuthentication();
  const { fetchMasterData } = useApi();

  useEffect(() => {
    const getRegionalManagements = (masterDataResponse: Store[]): RegionalManagement[] => {
      const regionalManagements: RegionalManagement[] = [];
      masterDataResponse.forEach(store => {
        if (
          regionalManagements.findIndex(item => item.id === store.regionalMgmtId) === -1 &&
          store.regionalMgmtId !== null
        ) {
          regionalManagements.push({
            label: store.regionalMgmt,
            id: store.regionalMgmtId,
            divisionId: store.divisionId,
            country: store.country,
          });
        }
      });
      return regionalManagements;
    };

    const getRegions = (masterDataResponse: Store[]): Region[] => {
      const regions: Region[] = [];
      masterDataResponse.forEach(store => {
        if (regions.findIndex(r => r.id === store.regionId) === -1 && store.regionId !== null) {
          regions.push({
            label: store.region,
            id: store.regionId,
            divisionId: store.divisionId,
            country: store.country,
            regionalMgmtId: store.regionalMgmtId,
          });
        }
      });
      return regions;
    };

    const getManagements = (masterDataResponse: Store[]): Management[] => {
      const managements: Management[] = [];
      masterDataResponse.forEach(store => {
        if (managements.findIndex(m => m.id === store.mgmtId) === -1 && store.mgmtId !== null) {
          managements.push({
            label: store.mgmt,
            id: store.mgmtId,
            divisionId: store.divisionId,
            country: store.country,
            regionalMgmtId: store.regionalMgmtId,
            regionId: store.regionId,
          });
        }
      });
      return managements;
    };

    const getSupervisions = (masterDataResponse: Store[]): Supervision[] => {
      const supervisions: Supervision[] = [];
      masterDataResponse.forEach(store => {
        if (
          supervisions.findIndex(s => s.id === store.supervisionId) === -1 &&
          store.supervisionId !== null
        ) {
          supervisions.push({
            label: store.supervision,
            id: store.supervisionId,
            divisionId: store.divisionId,
            country: store.country,
            regionalMgmtId: store.regionalMgmtId,
            regionId: store.regionId,
            mgmtId: store.mgmtId,
          });
        }
      });
      return supervisions;
    };

    const getCountries = (masterDataResponse: Store[]): Country[] => {
      const countries: Country[] = [];
      masterDataResponse.forEach(store => {
        if (countries.findIndex(country => country.label === store.country) === -1) {
          countries.push({
            division: store.division,
            divisionId: store.divisionId,
            id: store.countryId,
            label: store.country,
          });
        }
      });
      return countries;
    };

    const getDivisions = (masterDataResponse: Store[]) => {
      const divisions: Set<string> = new Set();
      masterDataResponse.forEach(s => divisions.add(s.divisionId));
      return [...divisions];
    };

    const init = async () => {
      try {
        setLoading(true);
        const masterDataRegister = sessionStorage.getItem('master-data');
        if (masterDataRegister !== null) {
          const savedMasterData: MasterData = JSON.parse(masterDataRegister);
          setMasterData(savedMasterData);
          setCompanyDivisions(savedMasterData.divisions);
        } else {
          if (isAuthenticated) {
            const masterDataResponse = await fetchMasterData();
            const divisions = getDivisions(masterDataResponse.data);
            setCompanyDivisions(divisions);

            const countries = getCountries(masterDataResponse.data);
            const regionalManagements = getRegionalManagements(masterDataResponse.data);
            const regions: Region[] = getRegions(masterDataResponse.data);
            const managements: Management[] = getManagements(masterDataResponse.data);
            const supervisions: Supervision[] = getSupervisions(masterDataResponse.data);
            const companyStores = masterDataResponse.data.sort(compareStores);
            setMasterData({
              divisions,
              countries,
              regionalManagements,
              regions,
              managements,
              supervisions,
              companyStores,
            });
            sessionStorage.setItem(
              'master-data',
              JSON.stringify({
                divisions,
                countries,
                regionalManagements,
                regions,
                managements,
                supervisions,
                companyStores,
              })
            );
          }
        }
      } catch (error) {
        showError(error);
      } finally {
        setLoading(false);
      }
    };

    init();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const filterByDivision = () => {
    if (masterData) {
      setCountries(masterData.countries.filter(c => c.divisionId === division).map(c => c.label));
      setCompanyRegionalManagements(
        masterData.regionalManagements.filter(r => r.divisionId === division)
      );
      setCompanyRegions(masterData.regions.filter(r => r.divisionId === division));
      setCompanyManagements(masterData.managements.filter(m => m.divisionId === division));
      setCompanySupervisions(masterData.supervisions.filter(s => s.divisionId === division));
      setStores(masterData.companyStores.filter(store => store.divisionId === division));
    }
  };

  const filterByCountry = () => {
    if (masterData) {
      setCompanyRegionalManagements(
        masterData.regionalManagements.filter(r => countries.includes(r.country))
      );
      setCompanyRegions(masterData.regions.filter(r => countries.includes(r.country)));
      setCompanyManagements(masterData.managements.filter(m => countries.includes(m.country)));
      setCompanySupervisions(masterData.supervisions.filter(s => countries.includes(s.country)));
      setStores(masterData.companyStores.filter(store => countries.includes(store.country)));
    }
  };

  const filterByRegionalManagement = () => {
    if (masterData) {
      setCompanyRegions(
        masterData.regions.filter(r =>
          regionalManagements.map(rm => rm.id).includes(r.regionalMgmtId)
        )
      );
      setCompanyManagements(
        masterData.managements.filter(m =>
          regionalManagements.map(rm => rm.id).includes(m.regionalMgmtId)
        )
      );
      setCompanySupervisions(
        masterData.supervisions.filter(s =>
          regionalManagements.map(rm => rm.id).includes(s.regionalMgmtId)
        )
      );
      setStores(
        masterData.companyStores.filter(s =>
          regionalManagements.map(rm => rm.id).includes(s.regionalMgmtId)
        )
      );
    }
  };

  const filterByRegion = () => {
    if (masterData) {
      setCompanyRegionalManagements(
        masterData.regionalManagements.filter(rm =>
          regions.map(r => r.regionalMgmtId).includes(rm.id)
        )
      );
      setCompanyManagements(
        masterData.managements.filter(m => regions.map(r => r.id).includes(m.regionId))
      );
      setCompanySupervisions(
        masterData.supervisions.filter(s => regions.map(r => r.id).includes(s.regionId))
      );
      setStores(masterData.companyStores.filter(s => regions.map(r => r.id).includes(s.regionId)));
    }
  };

  const filterByManagement = () => {
    if (masterData) {
      setCompanyRegionalManagements(
        masterData.regionalManagements.filter(rm =>
          managements.map(m => m.regionalMgmtId).includes(rm.id)
        )
      );
      setCompanyRegions(
        masterData.regions.filter(r => managements.map(m => m.regionId).includes(r.id))
      );
      setCompanySupervisions(
        masterData.supervisions.filter(s => managements.map(m => m.id).includes(s.mgmtId))
      );
      setStores(
        masterData.companyStores.filter(s => managements.map(m => m.id).includes(s.mgmtId))
      );
    }
  };

  const filterBySupervision = () => {
    if (masterData) {
      setCompanyRegionalManagements(
        masterData.regionalManagements.filter(rm =>
          supervisions.map(s => s.regionalMgmtId).includes(rm.id)
        )
      );
      setCompanyRegions(
        masterData.regions.filter(r => supervisions.map(s => s.regionId).includes(r.id))
      );
      setCompanyManagements(
        masterData.managements.filter(m => supervisions.map(s => s.mgmtId).includes(m.id))
      );
      setStores(
        masterData.companyStores.filter(s =>
          supervisions.map(sup => sup.id).includes(s.supervisionId)
        )
      );
    }
  };

  useEffect(() => {
    filterByDivision();
    // eslint-disable-next-line
  }, [division, masterData]);

  useEffect(() => {
    if (countries.length > 0) filterByCountry();
    else filterByDivision();
    // eslint-disable-next-line
  }, [countries, masterData]);

  useEffect(() => {
    if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, regionalManagements]);

  useEffect(() => {
    if (regions.length > 0) {
      filterByRegion();
    } else if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, regions]);

  useEffect(() => {
    if (managements.length > 0) {
      filterByManagement();
    } else if (regions.length > 0) {
      filterByRegion();
    } else if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, managements]);

  useEffect(() => {
    if (supervisions.length > 0) {
      filterBySupervision();
    } else if (managements.length > 0) {
      filterByManagement();
    } else if (regions.length > 0) {
      filterByRegion();
    } else if (regionalManagements.length > 0) {
      filterByRegionalManagement();
    } else if (countries.length > 0) {
      filterByCountry();
    } else filterByDivision();
    // eslint-disable-next-line
  }, [masterData, supervisions]);

  /**
   * Autocomplete Filters
   */

  useEffect(() => {
    if (companyDivisions.length === 1) {
      updateFilters({ ...filters, division: companyDivisions[0] });
    }
    // eslint-disable-next-line
  }, [companyDivisions]);

  useEffect(() => {
    if (filters.division !== '' && companyCountries.length === 1) {
      updateFilters({ ...filters, countries: [...companyCountries] });
    }
    // eslint-disable-next-line
  }, [companyCountries]);

  useEffect(() => {
    if (filters.division !== '' && filters.countries.length > 0 && companyStores.length === 1) {
      updateFilters({ ...filters, stores: [...companyStores] });
    }
    // eslint-disable-next-line
  }, [companyStores]);

  return (
    <MasterDataContext.Provider
      value={{
        companyDivisions,
        companyCountries,
        companyRegionalManagements,
        companyRegions,
        companyManagements,
        companySupervisions,
        companyStores,
        loading,
      }}
    >
      {children}
    </MasterDataContext.Provider>
  );
};

export const useMasterData = () => useContext(MasterDataContext);
