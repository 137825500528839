import { createMuiTheme, MuiThemeProvider, useMediaQuery } from '@material-ui/core';
import React, { Suspense, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nextProvider } from 'react-i18next';
import ErrorFallback from './components/error-boundary';
import LoadingPage from './components/loading';
import { AlertProvider } from './contexts/alert';
import { ApiProvider } from './contexts/api';
import { AuthenticationProvider } from './contexts/authentication';
import { FiltersProvider } from './contexts/filters';
import { MasterDataProvider } from './contexts/master-data';
import { PreferencesProvider, usePreferences } from './contexts/preferences';
import { UserProvider } from './contexts/user';
import i18n from './i18n';
import Router from './router';
import * as serviceWorker from './serviceWorker';

const WrappedApp = () => {
  const { preferences } = usePreferences();
  const systemTheme = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';
  let prefersDarkMode: 'light' | 'dark' = 'light';
  switch (preferences.theme) {
    case 'dark':
      prefersDarkMode = 'dark';
      break;
    case 'light':
      prefersDarkMode = 'light';
      break;
    case 'system':
      prefersDarkMode = systemTheme;
  }
  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: '#E88D3F',
          },
          secondary: {
            main: '#DA291C',
          },
          type: prefersDarkMode,
        },
      }),
    [prefersDarkMode]
  );
  return (
    <I18nextProvider i18n={i18n}>
      <MuiThemeProvider theme={theme}>
        <AlertProvider>
          <AuthenticationProvider>
            <ApiProvider>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<LoadingPage />}>
                  <UserProvider>
                    <FiltersProvider>
                      <MasterDataProvider>
                        <Router />
                      </MasterDataProvider>
                    </FiltersProvider>
                  </UserProvider>
                </Suspense>
              </ErrorBoundary>
            </ApiProvider>
          </AuthenticationProvider>
        </AlertProvider>
      </MuiThemeProvider>
    </I18nextProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <PreferencesProvider>
      <WrappedApp />
    </PreferencesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
