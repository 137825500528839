import React, { createContext, ReactNode, useState, useContext, useEffect } from 'react';
import { ThemePreference, UserPreferences } from '../types';

interface PreferencesContext {
  preferences: UserPreferences;
  updatePreferences: (newPreference: Partial<UserPreferences>) => void;
  setTheme: (type: ThemePreference) => void;
}

const initialState: PreferencesContext = {
  preferences: {
    theme: 'system',
    showSaveDialog: true,
  },
  updatePreferences: (_newPreference: Partial<UserPreferences>) => {},
  setTheme: () => {},
};

const PreferencesContext = createContext(initialState);

interface PreferencesProvider {
  children: ReactNode;
}

export const PreferencesProvider = ({ children }: PreferencesProvider) => {
  const [preferences, setPreferences] = useState<UserPreferences>(initialState.preferences);

  useEffect(() => {
    const storedPreferences = localStorage.getItem('preferences');
    if (storedPreferences) {
      setPreferences(JSON.parse(storedPreferences));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('preferences', JSON.stringify(preferences));
  }, [preferences]);

  const setTheme = (theme: ThemePreference) => {
    setPreferences(preferences => ({ ...preferences, theme: theme }));
  };

  const updatePreferences = (newPreference: Partial<UserPreferences>) => {
    setPreferences(preferences => ({ ...preferences, ...newPreference }));
  };

  return (
    <PreferencesContext.Provider value={{ updatePreferences, setTheme, preferences }}>
      {children}
    </PreferencesContext.Provider>
  );
};

export const usePreferences = () => useContext(PreferencesContext);
