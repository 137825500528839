import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import React, { createContext, ReactNode, useContext } from 'react';
import {
  LabelLanguage,
  AppPermission,
  Store,
  TransformedFilters,
  WayStatus,
  FilterLabels,
} from '../types';
import { useAuthentication } from './authentication';

interface ApiContext {
  fetchMasterData: () => AxiosPromise<Store[]>;
  fetchWayStatus: (
    queryParameters: TransformedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => AxiosPromise<WayStatus[]>;
  fetchPermissions: () => AxiosPromise<AppPermission[]>;
}

const initialState: ApiContext = {
  fetchMasterData: () => new Promise(() => {}),
  fetchWayStatus: (_queryParameters: TransformedFilters) => new Promise(() => {}),
  fetchPermissions: () => new Promise(() => {}),
};

const ApiContext = createContext(initialState);

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const { getToken } = useAuthentication();
  const flexApi = Axios.create({
    baseURL:
      (process.env.REACT_APP_API_URL && `${process.env.REACT_APP_API_URL}/rs`) ||
      'https://api-flex-dev.arcosdorados.net/rs',
  });
  const wayApi = Axios.create({
    baseURL:
      (process.env.REACT_APP_API_URL && `${process.env.REACT_APP_API_URL}/way`) ||
      'https://api-flex-dev.arcosdorados.net/way',
  });

  flexApi.interceptors.request.use(async (value: AxiosRequestConfig) => {
    const { accessToken, idToken } = await getToken();
    if (idToken && accessToken) {
      value.headers = {
        'access-token': accessToken,
        Authorization: `Bearer ${idToken}`,
        appId: 'way',
      };
      return value;
    } else throw Error('token-error');
  });

  wayApi.interceptors.request.use(async (value: AxiosRequestConfig) => {
    const { accessToken, idToken } = await getToken();
    if (idToken && accessToken) {
      value.headers = {
        'access-token': accessToken,
        Authorization: `Bearer ${idToken}`,
        appId: 'way',
      };
      return value;
    } else throw Error('token-error');
  });

  const fetchWayStatus = async (
    queryParameters: TransformedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => {
    return wayApi.get('/status', {
      params: { ...queryParameters, export: exportRequest, labels, filterLabels },
    });
  };

  const fetchMasterData = () => flexApi.get('/op-structures');

  const fetchPermissions = () => flexApi.get('/permissions');

  return (
    <ApiContext.Provider value={{ fetchWayStatus, fetchMasterData, fetchPermissions }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);
