import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { WayStatusProvider } from '../../../contexts/way-status';
import { PrivateRoute } from '../../../router';

const Report = lazy(() => import('../../../pages/way-report'));
const Profile = lazy(() => import('../../../pages/profile'));
const Help = lazy(() => import('../../help'));

const ContentRouter = () => (
  <WayStatusProvider>
    <Switch>
      <PrivateRoute path="/way-status" component={Report} />
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/help" component={Help} />
    </Switch>
  </WayStatusProvider>
);

export default ContentRouter;
